*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  background-color: #111112;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  width: fill-available;
  width: -webkit-fill-available;
  height: 100vh;
  max-height: fill-available;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: fixed;
  margin: 0;
  font-size: clamp(.5rem, 3.4vmin, 1.5rem);
  transition: background-color .5s ease-in;
}

.App.light {
  background-color: #fff;
}