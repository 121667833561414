.tile {
    font-size: clamp(1.7em, 5vh, 2.2em);
    color: white;
    border: .07em solid hsl(240, 2%, 23%);
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition:
        transform 250ms linear,
        color 250ms ease,
        border 500ms ease-in;
}

.tile.light {
    color: black;
    border: .07em solid hsl(214, 9%, 84%);
}

.tile.active {
    border-color: hsl(200, 1%, 34%);
}

.tile.active.light {
    border-color: hsl(204, 2%, 54%);
}

.tile.wrong-letter {
    background-color: hsl(240, 2%, 23%);
    border: none;
}

.tile.wrong-letter.light {
    background-color: hsl(200, 2%, 48%);
    color: white;
    border: none;
}

.tile.wrong-location {
    background-color: hsl(49, 51%, 47%);
    border: none;
}

.tile.wrong-location.light {
    background-color: hsl(49, 51%, 57%);
    color: white;
    border: none;
}

.tile.correct {
    background-color: hsl(115, 29%, 43%);
    border: none;
}

.tile.correct.light {
    background-color: hsl(115, 29%, 53%);
    color: white;
    border: none;
}

.tile.shake {
    animation: shake 250ms ease-in-out;
}

.tile.flip {
    transform: rotateX(90deg);
}

.tile.dance {
    animation: dance 500ms ease-in-out;
}

.tile.pop {
    animation-name: PopIn;
    animation-duration: 100ms;
}

@keyframes PopIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    40% {
        transform: scale(1.1);
        opacity: 1;
    }
}

@keyframes dance {
    20% {
        transform: translateY(-50%);
    }

    40% {
        transform: translateY(5%);
    }

    60% {
        transform: translateY(-25%);
    }

    80% {
        transform: translateY(-2.5%);
    }

    90% {
        transform: translateY(-5%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes shake {
    10% {
        transform: translate(-5%);
    }

    30% {
        transform: translate(5%);
    }

    50% {
        transform: translate(-7.5%);
    }

    70% {
        transform: translate(7.5%);
    }

    90% {
        transform: translate(5%);
    }

    100% {
        transform: translate(0);
    }

}