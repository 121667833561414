@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

.title {
    font-size: clamp(1.5rem, 5.5vh, 3.5rem);
    font-family: 'Merriweather', serif;
    color: white;
    transition: color .5s ease-in;
}

.light {
    color: black;
}