.navbar {
    flex: 0 0 auto;
    background-color: inherit;
    border-bottom: .08rem solid hsl(240, 2%, 23%);
    padding: .6rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: border-bottom .5s ease-in;
}

.navbar.light {
    border-bottom: .08rem solid hsl(214, 9%, 84%)
}