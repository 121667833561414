.guess-grid {
    display: grid;
    justify-content: center;
    align-content: center;
    gap: .25em;
    margin-top: 4vh;
    margin-bottom: 3vh;
    flex-grow: .3;
    grid-template-columns: repeat(5, clamp(2.5vw, 6vh, 7rem));
    grid-template-rows: repeat(6, clamp(2.5vw, 6vh, 7rem));
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1000px) and (min-height: 820px) {
    .guess-grid {
        grid-template-columns: repeat(5, clamp(3vw, 8vh, 7.5rem));
        grid-template-rows: repeat(6, clamp(3vw, 8vh, 7.5rem));
    }
}

/* Desktop */
@media only screen and (min-width: 1001px) and (min-height: 820px) {
    .guess-grid {
        grid-template-columns: repeat(5, clamp(3vw, 9vh, 8.5rem));
        grid-template-rows: repeat(6, clamp(3vw, 9vh, 8.5rem));
    }
}