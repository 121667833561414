.key {
    font-size: inherit;
    grid-column: span 2;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(var(--hue, 200),
            var(--saturation, 1%),
            calc(var(--lightness-offset, 0%) + var(--lightness, 51%)));
    color: white;
    fill: white;
    text-transform: uppercase;
    border-radius: .25em;
    cursor: pointer;
    user-select: none;
    transition: background-color .5s ease-in, box-shadow .5s ease-in, color .5s ease-in;
}

.key.light {
    background-color: hsl(var(--hue, 214),
            var(--saturation, 9%),
            calc(var(--lightness-offset, 0%) + var(--lightness, 84%)));
    color: black;
    fill: black;
}

.key.large {
    grid-column: span 3;
}

.key>svg {
    width: 1.75em;
    height: 1.75em;
}

.key:focus {
    --lightness-offset: 5%;
    box-shadow: 0px 0px 1px 1px hsl(115, 54%, 57%);
    transition: none;
    outline: none;
}

.key:hover {
    --lightness-offset: 10%;
    box-shadow: 0px 0px 2px 3px hsl(115, 54%, 57%);
    transition: none;
    outline: none;
}

.key:active {
    box-shadow: 0px 0px 5px 5px hsl(115, 54%, 57%);
    font-size: 90%;
}

.key.wrong {
    --lightness: 23%;
}

.key.wrong-location {
    --hue: 49;
    --saturation: 51%;
    --lightness: 47%;
}

.key.correct {
    --hue: 115;
    --saturation: 29%;
    --lightness: 43%;
}

.key.wrong.light {
    --lightness: 48%;
}

.key.wrong-location.light {
    --hue: 49;
    --saturation: 51%;
    --lightness: 57%;
}

.key.correct.light {
    --hue: 115;
    --saturation: 29%;
    --lightness: 53%;
}