@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&display=swap');

.new-game {
    border-radius: 1rem;
    border: none;
    padding: .3rem .9rem;
    transition: all 0.2s ease;
    color: whitesmoke;
    background-color: hsl(210, 1%, 51%);
    font-family: 'Fira Code', monospace;
    transition: all .5s ease-in;
}

.new-game.light {
    background-color: hsl(214, 9%, 84%);
    color: black;
}

.new-game:focus {
    background-color: hsl(210, 1%, 41%);
    transition: all 0.2s ease;
    box-shadow: 0px 0px 1px 2px hsl(115, 54%, 57%);
    outline: none;
}

.new-game.light:focus {
    background-color: hsl(214, 9%, 64%);
    color: rgb(242, 242, 242);
    transition: all 0.2s ease;
    box-shadow: 0px 0px 1px 2px hsl(115, 54%, 57%);
    outline: none;
}

.new-game:hover,
.new-game.light:hover {
    background-color: hsl(214, 7%, 28%);
    transition: all 0.2s ease;
    color: rgb(242, 242, 242);
    box-shadow: 0px 0px 2px 3px hsl(115, 54%, 57%);
    outline: none;
}

.new-game:active {
    box-shadow: 0px 0px 5px 5px hsl(115, 54%, 57%);
    font-size: small;
}