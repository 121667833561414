.alert-container {
    position: fixed;
    top: 10vh;
    left: 50vw;
    transform: translate(-50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert {
    pointer-events: none;
    background-color: hsl(204, 7%, 85%);
    padding: 0.75em;
    border-radius: 0.25em;
    opacity: 1;
    transition: opacity 500ms ease-in-out;
    margin-bottom: 0.5em;
}


.hide {
    opacity: 0;
}