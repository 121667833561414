.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0 1.3rem;
    user-select: none;
    transition: all .5s ease;

}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: rgb(39, 39, 39);
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;

}

.react-toggle--checked .react-toggle-track {
    background-color: hsl(214, 9%, 74%);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: hsl(214, 9%, 44%);

}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid rgba(212, 212, 212, 0);
    border-radius: 50%;
    background-color: #818283;

    box-sizing: border-box;

    transition: all 0.5s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #ffd00071;
    background-color: #FAFAFA;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px hsl(115, 54%, 57%);
}

.react-toggle-thumb:hover {
    box-shadow: 0px 0px 2px 3px hsl(115, 54%, 57%);

}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    text-shadow: 0px 0px 5px 5px hsl(115, 54%, 57%);
}